import React, { useState, useEffect } from 'react';
import { Label, Button, FileInput, Progress } from 'flowbite-react';
import { useForm, SubmitHandler } from "react-hook-form";
import axios from 'axios';
import { getApiUrl } from '../utils/url';
import { ref, getDownloadURL } from "firebase/storage";
import { firebaseStorage } from '../../firebase';
import RadioButtonGroup from './RadioButtonGroup';

type FormValues = {
  fps: '1' | '25' | '30' | '60'; 
  orientation: 'horizontal' | 'vertical';
  resolution: '4k' | 'fullhd' | 'hd';
  gpx: any,
}

function RenderDetailsForm() {
  const formHandler = useForm<FormValues>({
    defaultValues: {
      fps: '1',
      orientation: 'horizontal',
      resolution: 'hd'
    },
    mode: 'onChange'
  });
  const { register, handleSubmit, formState } = formHandler;
  const [renderZipPath, setRenderZipPath] = useState<string>(); 
  const [jobId, setJobId] = useState<number>(); 
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const data = new FormData();

    data.append('gpx', formData.gpx[0]);
    data.append('fps', formData.fps);
    data.append('orientation', formData.orientation);
    data.append('resolution', formData.resolution);

    axios.postForm(getApiUrl('gpx'), data).then(({data}) => {
      setRenderZipPath(data.render_zip_path);
      setJobId(data.job_id);
    });
  };

  const onClickToDownloadButton = async () => {
    if (!renderZipPath) return;
    
    const renderZipRef = ref(firebaseStorage, renderZipPath);

    const href = await getDownloadURL(renderZipRef);

    const link = document.createElement('a');
    link.href = href;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const checkRenderProgress = () => {
    axios.get(getApiUrl(`/render-progress?job_id=${jobId}`))
      //{ params: { job_id: jobId } }
      .then(({ data }: { data: { progress: number; is_finished: boolean } }) => {
        const { progress, is_finished: isFinished } = data;

        if (isFinished) {
          setIsFinished(true);
          setProgress(1);
        } else {
          setProgress(progress);
          setTimeout(() => {
            checkRenderProgress();
          }, 1000);
        }
      });
  }

  const fpsChoosenOption = formHandler.watch('fps');

  useEffect(() => {
    if (!jobId) { return }
    checkRenderProgress();
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <h1 className='text-2xl font-bold'>Generate your overlays:</h1>

      <Label htmlFor='gpx'>
        Upload your .gpx file you can get from strava/garmin or other device:
      </Label>
      <FileInput
        id="gpx"
        accept=".gpx,application/gpx+xml"
        {...register("gpx", {required: "Gpx file is required"})}
      />

      <fieldset className="flex flex-col gap-4">
        <legend>
          Choose FPS you use in your video editor:
        </legend>

        <RadioButtonGroup
          formHandler={formHandler}
          inputName="fps"
          options={[
            { name: '1', label: '1 FPS' },
            { name: '25', label: '25 FPS' },
            { name: '30', label: '30 FPS' },
            { name: '60', label: '60 FPS' },
          ]}
        />
        
        {
          fpsChoosenOption === '1' && (
            <span className="text-xs italic text-blue-400 flex">
              <svg className="w-6 h-6 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg> 
              1 FPS means less frames, quicker processing. To match your video FPS, slow down to desired speed.
              Eg. To match 50fps slow down to 0.02. (1/50 = 0.02)
            </span>
          )
        }
      </fieldset>

      <fieldset className="flex flex-col gap-4">
        <legend>
          Choose your video orientation:
        </legend>

        <RadioButtonGroup
          formHandler={formHandler}
          inputName="orientation"
          options={[
            { name: 'horizontal', label: 'Horizontal' },
            { name: 'vertical', label: 'Vertical' },
          ]}
        />
      </fieldset>

      <fieldset className="flex flex-col gap-4">
        <legend>
          Choose your video resolution:
        </legend>

        <RadioButtonGroup
          formHandler={formHandler}
          inputName="resolution"
          options={[
            {name:'hd', label:'720p (HD)'},
            {name:'fullhd', label:'1080p (FullHD)'},
            {name:'4k', label:'2160p (4k)'},
          ]}
        />
      </fieldset>

      <Button color="primary" type="submit" disabled={!formState.isValid || !!renderZipPath} className='mt-4'>
        Generate
      </Button>

      {
        renderZipPath && (
          <Progress progress={progress*100} />
        )
      }

      {
        renderZipPath && (
          <Button color="primary" disabled={!isFinished} onClick={onClickToDownloadButton}>
            Click to download
          </Button>
        )
      }
    </form>
  )
}

export default RenderDetailsForm;
