import React from 'react';
import './App.css';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import { Flowbite } from "flowbite-react";
import type { CustomFlowbiteTheme } from "flowbite-react";
import { ErrorBoundary } from './bugsnag';

/* 
  BRAND COLORS:
  Brand/Primary: #22223B
  Secondary: #FF1919
  Contrast: #FBFF12
  White: #F9FAFF
  Black: #121045
*/

const customTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      primary: "bg-primary-500 text-white",
    },
  },
};

const hideSeoDescription = () => {
  const a = document.querySelector('.no-script-seo-description');
  a?.classList.add('hidden');
}

function App() {
  hideSeoDescription();

  return (
    <ErrorBoundary>
      <Flowbite theme={{ theme: customTheme }}>
        <Header/>
        <MainContent/>
        <Footer/>
      </Flowbite>
    </ErrorBoundary>
  );
}

export default App;
