import React from 'react';
import { Button } from 'flowbite-react';

function RadioButtonGroup({
  formHandler,
  inputName,
  options
}: {
  formHandler: any,
  inputName: string,
  options: Array<{name: string, label: string}>
}) {
  const { register, setValue, watch } = formHandler;
  const selectedOption = watch(inputName);

  return (
    <div>
      <Button.Group>
        {
          options.map(({name, label}) => (
            <Button
              type="button"
              color={selectedOption === name ? 'primary' : 'gray'}
              onClick={() => setValue(inputName, name)}
              key={`radio-button-group-${name}`}
            >
              {label}
            </Button>
          ))
        }
      </Button.Group>

      {/* Hidden input for form submission */}
      {
        options.map(({ name }) => (
          <input
            type="radio"
            value={name}
            {...register(inputName)}
            className="hidden"
            key={`radio-button-group-radio-${name}`}
          />
        ))
      }
    </div>
  );
}

export default RadioButtonGroup;
