import { IS_PROD, API_HOST } from "./env"

function getBaseUrl() {
  const protocol = IS_PROD ? 'https://' : 'http://';
  return `${protocol}${API_HOST}`;
}

export function getApiUrl(path:string) {
  const base = getBaseUrl();
  
  if (path[0] === '/'){
    return `${base}${path}`;
  }
  
  return `${base}/${path}`;
}
