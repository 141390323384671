import React from 'react';
import Bugsnag, {Event} from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { IS_PROD } from './components/utils/env';

const ErrorBoundaryPlaceholder = ({ children }: { children: React.ReactNode }) => (<>{ children } </>);

if (IS_PROD) {
  Bugsnag.start({
    apiKey: '820ea8d2c9e9fdd797e75fa397a1ca55',
    plugins: [new BugsnagPluginReact()]
  })
  BugsnagPerformance.start({ apiKey: '820ea8d2c9e9fdd797e75fa397a1ca55' });
}

const BugSnagErrorBoundary = IS_PROD && Bugsnag.getPlugin('react')
  ?.createErrorBoundary(React)

export const ErrorBoundary = IS_PROD && BugSnagErrorBoundary
  ? BugSnagErrorBoundary
  : ErrorBoundaryPlaceholder;

export const throwError = (err: Error, level: Event['severity'], details: Object):void => {
  Bugsnag.notify(err, (event) => {
    event.severity = level;
    event.addMetadata('details', details)
  })
}
